import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { validateToken } from 'api/token';

const isTokenValid = async () => {
    return await validateToken()
};

const PrivateRoute = () => {
  const token = localStorage.getItem('token');

  const [isValid, setIsValid] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const checkToken = async () => {
      const valid = await isTokenValid(token);
      setIsValid(valid);
      setLoading(false);
    };
    checkToken();
  }, [token]);

  if (loading) {
    return <div>Loading...</div>; // Optionally show a loading spinner or message
  }

  return isValid ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
