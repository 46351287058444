import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { queryKeywords } from 'api/query';

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginTop: '2%',
    backgroundColor: 'transparent !important', // Set the background color to transparent
    boxShadow: 'none !important', // Remove the box shadow if not needed
  },
  toolbar: {
    backgroundColor: 'transparent', // Set the background color to transparent
    justifyContent: 'center',
  },
  searchInput: {
    width: '100%',
    maxWidth: 600,
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  inputForm: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '80%', // Adjust width for mobile devices
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%', // Further adjust width for smaller mobile devices
    },
  },
}));

const SearchBar = ({ setResultQuery, setLoading, loading }) => {
  const [input, setInput] = useState('');
  const classes = useStyles();

  const onSubmit = async (evt) => {
    setLoading(true)
    evt.preventDefault();
    try{ 
    const result = await queryKeywords(input);
    setLoading(false)
    setResultQuery(result);
    }
    catch(e) {
    setLoading(false)
    }
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <form onSubmit={onSubmit} className={classes.inputForm}>
          <InputBase
            placeholder="Keywords..."
            className={classes.searchInput}
            inputProps={{ 'aria-label': 'search' }}
            value={input}
            onChange={(evt) => setInput(evt.target.value)}
            endAdornment={
              <IconButton type="submit" aria-label="search" disabled={loading}>
                <SearchIcon />
              </IconButton>
            }
          />
        </form>
      </Toolbar>
    </AppBar>
  );
};

export default SearchBar;
