import axios from "axios"


export const apiClient = () => { 

  const token = localStorage.getItem("token")

  return axios.create({
    baseURL: "/api/",
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Authorization" : token ? ` Bearer ${token}` : undefined
      }})

    }

      