import React from 'react';
import { Card, CardContent, Typography, CircularProgress } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { IconButton } from '@mui/material';
import { SpotifyIconWhite } from 'icons';

const WhiteSpotifyIconButton = ({artistId}) => {
    return (
        <IconButton color="inherit" target='_blank' href={`https://open.spotify.com/artist/${artistId}`}>
            <SpotifyIconWhite />
        </IconButton>
    );
}


const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative',
    backgroundColor: `${theme.palette.primary.main} !important`, // Secondary theme background
    maxWidth: '500px',
    height : '150px',
    maxHeight: '150px',

  },
  circleContainer: {
    marginLeft: 'auto', // Pushes circle to the right
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center', // Center text horizontally
  },
}));

const ArtistCard = ({ artist, genre, relatedPercentage, spotifyId }) => {
  const classes = useStyles();
  const theme = useTheme();

  const getColor = (percentage) => {
    const hue = (percentage * 120) / 100; // Convert percentage to hue (0-120)
    return `hsl(${hue}, 100%, 50%)`; // Convert hue to color
  };

  return (
    <Card className={classes.card}>
         <CardContent sx={{ 'color': 'white' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5">{artist}</Typography>
                </div>
                <Typography variant="subtitle1" color="textSecondary">{genre}</Typography>
            </CardContent>
      <div className={classes.circleContainer}>
        <div className={classes.circleContent}>
          <CircularProgress
            variant="determinate"
            size={50}
            thickness={4}
            value={relatedPercentage}
            style={{ color: getColor(relatedPercentage) }}
          />
          <Typography variant="h6" style={{ color: 'black', marginTop: theme.spacing(1) }}>
            {relatedPercentage}
          </Typography>
          { spotifyId? <WhiteSpotifyIconButton artistId={spotifyId}/> : null }
        </div>
      </div>
    </Card>
  );
};

export default ArtistCard;
