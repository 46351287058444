import { apiClient } from "api";

export const validateToken = async () => { 
    try { 
     await apiClient().get("/token")
    } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403) ) {
            return false; // Return false if the status code is 401
          } else {
            throw error; // Re-throw the error if it's not a 401 status code
          }
    }
    return true
}
export const generateToken = async ({username,password}) => { 
  try { 
   const res = await apiClient().post("/token", {username,password} )
   return res.data
  } catch (error) {
          throw error; // Re-throw the error if it's not a 401 status code
        }
  }