import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    width: '50%',
    backgroundColor: theme.palette.secondary.main, // Mecha neon purple background
    minHeight: '15vh', // Set a minimum height of 15% of the viewport height
    display: 'flex', // Use flexbox for vertical centering
    flexDirection: 'column', // Stack child elements vertically
    justifyContent: 'center', // Center child elements vertically
    alignItems: 'center', // Center child elements horizontally
    padding: '32px', // Increased padding
    color: 'black',
    fontFamily: 'monospace',
    fontSize: '24px',
    textAlign: 'center',
    borderRadius: '8px', // Added border radius
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Added box shadow
    [theme.breakpoints.down('sm')]: {
      width: '70%', // Adjust width for mobile devices
      fontSize: '20px', // Adjust font size for mobile devices
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%', // Further adjust width for smaller mobile devices
      fontSize: '18px', // Further adjust font size for smaller mobile devices
    },
  },
}));

function HeaderCard() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>Show Me Artists</div>
    </div>
  );
}

export default HeaderCard;
