import { apiClient } from "api";

/**
 * 
 * @param {string} keywords 
 * @returns {object}
 */
export const queryKeywords = async (keywords) => { 
    const result = await apiClient().post("/query" , { 
        "keywords": keywords
    })
    return result.data
}

export const getAllQueries = async () => { 
    const result = await apiClient().get("/query")
    return result.data
}


export const getQuery = async (objectid) => { 
    const result = await apiClient().get(`/query/${objectid}`)
    return result.data
}

