import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'theme';
import HeaderComponent from 'components/HeaderComponent';
import SearchBar from 'components/SearchComponent';
import { Container } from '@mui/material';
import ArtistCardGrid from 'components/ArtistCardGridComponent';
import HistoryPanel from 'components/HistoryPanelComponent';
import { useQuery } from 'react-query';
import { getAllQueries } from 'api/query';

function Main() {
  const [queryResult, setQueryResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const { data, isLoading,error,refetch} = useQuery('getAllQueries', getAllQueries);
  
  useEffect(() => {
    refetch()
  }, [queryResult])

  return (
    <ThemeProvider theme={theme}>
      <HistoryPanel keywords={(!isLoading && !error) ? data : [] }  setQueryResult={setQueryResult} />
      <Container>
        <HeaderComponent />
      </Container>
      <Container>
        <SearchBar setResultQuery={setQueryResult} setLoading={setLoading} loading={loading} />
      </Container>
      <Container sx={{ marginTop: "1.5%" }}>
        <ArtistCardGrid data={queryResult} loading={loading} />
      </Container>
    </ThemeProvider>
  );
}

export default Main;
