// HistoryPanel.js
import React, { useState } from 'react';
import { IconButton, Drawer, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { getQuery } from 'api/query';
import { useTheme } from '@mui/styles';
const HistoryPanel = ({ keywords, open, onClose, setQueryResult }) => {
    const theme = useTheme();

    const onSubmit = async (objectid) => {
        const queryObject = await getQuery(objectid)
        setQueryResult(queryObject.result)
    }
    return (
        <Drawer PaperProps={{
            sx: {
                backgroundColor: theme.palette.primary.main,
                color: "white"
            }
        }}
            anchor="left" open={open} onClose={onClose}>
            <div style={{ width: 250, padding: 16 }}>
                <Typography variant="h6">History</Typography>
                <List>
                    {keywords.map(({ title, _id }, index) => (
                        <ListItemButton key={index} onClick={() => onSubmit(_id)} >
                            <ListItemText primary={title} />
                        </ListItemButton>
                    ))}
                </List>
            </div>
        </Drawer>
    );
};

const HistoryPanelContainer = ({ keywords, setQueryResult }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    return (
        <div>
            <IconButton onClick={handleDrawerOpen}>
                <MenuIcon style={{ color: '#fff' }}/>
            </IconButton>
            <HistoryPanel keywords={keywords ? keywords : []} setQueryResult={setQueryResult} open={isDrawerOpen} onClose={handleDrawerClose} />
        </div>
    );
};



export default HistoryPanelContainer;
