import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#9370DB', // Mecha neon purple
    },
    secondary: {
      main: '#90EE90', // Faint green
    },
  },
});

export default theme;