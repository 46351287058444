import React from 'react';
import { Grid, CircularProgress, Box } from '@mui/material';
import ArtistCard from './ArtistCardComponent';

const ArtistCardGrid = ({ data, loading }) => {
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      {data.map((item, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
          <ArtistCard
            artist={item.artist}
            genre={item.genre}
            relatedPercentage={item.related_percentage}
            spotifyId={item.spotify_id}

          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ArtistCardGrid;
