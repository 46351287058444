import React from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import PrivateRoute from './components/PrivateRouteComponent';
import { QueryClient, QueryClientProvider } from 'react-query'


const AppRoutes = () => {
  const routes = useRoutes([
    { path: '/', element: <PrivateRoute />, children:  [{ path: '/', element: <HomePage /> },]},
    { path: '/login', element: <LoginPage />},

  ]);

  return routes;
};


const App = () => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
